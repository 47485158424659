import "./App.css";
import NomadList from "./components/nomadList";
import React, { Component } from "react";
import Map from "./components/map";
import { Helmet } from "react-helmet";
import LoadingSpinner from "./components/loadingSpinner";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
      loadingCoordinates: true,
    };
    this.mapRef = React.createRef();
  }

  componentDidMount() {}

  handleCoordinates = (lng, lat) => {
    console.log("received coords in App.js");
    this.setState({ lng: lng, lat: lat, loadingCoordinates: false }, () => {
      this.mapRef.current.updateCoordinates(lng, lat);
      console.log("sending coords to Map.js", lng, lat);
    });
  };

  render() {
    const { loadingCoordinates } = this.state;
    return (
      <div>
        <Helmet>
          <title>Where is Luke?</title>
        </Helmet>
        <div className="h-minbg-white text-black">
          <div className="flex flex-wrap flex-row h-screen">
            <div className="w-full h-2/5">
              {loadingCoordinates ? (
                <div className="w-full text-center mt-12">
                  <LoadingSpinner />
                  <p className="pt-8 text-xs text-gray-600 text-center">
                    "loading .."
                  </p>
                </div>
              ) : (
                <Map ref={this.mapRef} />
              )}
            </div>
            <div className="w-full h-3/5">
              <NomadList onCoordinatesChange={this.handleCoordinates} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
