import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
// import greatCircle from "@turf/great-circle";
// import { lineStrings, multiLineString, multiPoint, point } from "@turf/helpers";

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
      zoom: 1.5,
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    // destructure the props passed in from the App component

    mapboxgl.accessToken =
      "pk.eyJ1IjoibHVrYXNiZXJuZXJ0IiwiYSI6ImNrejZndzdweTAyczAyb21tYW1mdmprdmcifQ.xqUygQG97yfvkWtS-ks92w";

    if (!mapboxgl.supported()) {
      alert("Your browser does not support Mapbox GL");
    } else {
      this.map = new mapboxgl.Map({
        container: this.mapContainer.current, // html element id in render
        style: "mapbox://styles/mapbox/streets-v11",
        renderWorldCopies: false,
        zoom: [this.state.zoom],
      });
      console.log("Created map in Map.js");

      this.marker = new mapboxgl.Marker({
        color: "#000000",
        draggable: true,
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.map.remove();
  }

  renderMap() {}

  calculateTripLines(destinations) {
    for (let i in destinations) {
    }
  }

  updateCoordinates(lng, lat) {
    console.log("received coords in Map.js", lng, lat);
    this.setState(
      { lng, lat },
      () => this.map.setCenter([this.state.lng, this.state.lat]),
      this.marker.setLngLat([lng, lat]).addTo(this.map),
      console.log("updated coords in map.js")
    );
  }

  render() {
    return (
      <div className="w-full h-full">
        <div className="w-full h-full" ref={this.mapContainer} />
      </div>
    );
  }
}
