import React from "react";

function Footer() {
  return (
    <footer className="mt-auto mb-4 p-4 sm:p-1 xs:p-1 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-4 dark:bg-white">
      <span className="text-xs text-gray-600 xs:text-center sm:text-center dark:text-gray-400">
        Built by Luke - inspired by{" "}
        <a
          href="https://nownownow.com/about"
          className="text-xs text-gray-600 hover:underline dark:text-gray-400"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            window.analytics.track("Opened Link", {
              path: "/",
              destination: "nownownow.com/about",
            })
          }
        >
          Derek Sivers
        </a>
        {" & "}
        <a
          href="https://github.com/KrauseFx/whereisfelix.today"
          className="text-xs text-gray-600 hover:underline dark:text-gray-400"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            window.analytics.track("Opened Link", {
              path: "/",
              destination: "github.com/KrauseFx/whereisfelix.today",
            })
          }
        >
          KrauseFx
        </a>
      </span>
      <ul className="flex flex-wrap items-center mt-3 sm:mt-0">
        <li>
          <span className="mr-4 text-xs text-gray-600 dark:text-gray-400">
            Trip Data from{" "}
            <a
              href="https://nomadlist.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline md:mr-6 dark:text-gray-400"
              onClick={() =>
                window.analytics.track("Opened Link", {
                  path: "/",
                  destination: "nomadlist.com",
                })
              }
            >
              nomadlist.com
            </a>
          </span>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
