import React, { Component } from "react";
import axios from "axios";
import { emoji } from "../services/emoji";
import Footer from "./footer";
import LoadingSpinner from "./loadingSpinner";

const api = axios.create({
  baseURL: "https://nomadlist.com/@lukeb.json",
});

class NomadList extends Component {
  state = {
    now: null,
    previous: null,
    nextTrips: [],
    loading: true,
  };

  handleCoordinatesChange = (lng, lat) => {
    // send coordinates to parent
    console.log("Sending coords to App.js");
    this.props.onCoordinatesChange(lng, lat);
  };

  componentDidMount() {
    // get Nomadlist data

    api.get("/").then((res) => {
      console.log("Getting Nomadlist data");
      const { now, previous } = res.data.location;
      const { trips } = res.data;
      const nextTrips = [];

      // loop through trips array
      for (let i in trips) {
        // add to upcoming trips only if the current date
        // is smaller than the trip start date
        if (trips[i].epoch_start > new Date().getTime() / 1000) {
          nextTrips.push(trips[i]);

          // else stop
        } else {
          break;
        }
      }
      nextTrips.reverse();
      this.setState({ now, previous, nextTrips, loading: false });
      this.handleCoordinatesChange(now.longitude, now.latitude);
    });

    const date = new Date("2009-11-10"); // 2009-11-10
    const month = date.toLocaleString("default", {
      month: "short",
      day: "2-digit",
    });
    console.log(month);
  }

  getDuration(start, end) {
    // epoch is in seconds. 1 day has 86400 seconds
    let duration = (end - start) / 86400;
    return duration;
  }

  formatDate(date) {
    const d = new Date(date); // 2009-11-10
    const month = d.toLocaleString("default", {
      month: "short",
    });
    const day = d.toLocaleString("default", {
      day: "2-digit",
    });

    const dateFormatted = month + " " + day;
    return dateFormatted;
  }

  render() {
    const { now, previous, nextTrips } = this.state;
    return (
      <div className="flex flex-col px-8 h-full">
        {this.state.loading ? (
          <div className="w-full text-center mt-12">
            <LoadingSpinner />
            <p className="pt-8 text-xs text-gray-600 text-center">
              "loading .."
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p className="font-bold text-3xl pt-8 pb-8 text-center">
              Where is Luke?
            </p>
            <div className="pb-8">
              <p className="text-gray-600 text-xs">CURRENTLY IN:</p>
              <p className="text-lg font-bold">
                {now.city}, {now.country} {emoji[now.country_code]}
              </p>
              <p className="text-sm">
                for {this.getDuration(now.epoch_start, now.epoch_end)} days
                until {this.formatDate(now.date_end)}.
              </p>
            </div>

            <div className="flex align-middle justify-center text-left">
              <table className="text-center">
                <thead>
                  <tr>
                    <th className="border px-4">Trips</th>
                    <th className="border px-4">From </th>
                    <th className="border px-4">To</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700 text-sm">
                  <tr className="text-gray-500 line-through">
                    <td className="border px-4 py-2">
                      {previous.city}, {previous.country}{" "}
                      {emoji[previous.country_code]}
                    </td>
                    <td className="border px-4 py-2">
                      {this.formatDate(previous.date_start)}
                    </td>
                    <td className="border px-4 py-2">
                      {this.formatDate(previous.date_end)}
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td className="border px-4 py-2">
                      {now.city}, {now.country} {emoji[now.country_code]}
                    </td>
                    <td className="border px-4 py-2">
                      {this.formatDate(now.date_start)}
                    </td>
                    <td className="border px-4 py-2">
                      {this.formatDate(now.date_end)}
                    </td>
                  </tr>
                  {nextTrips.length > 0 &&
                    nextTrips.map((trip) => (
                      <tr key={trip.trip_id}>
                        <td className="border px-4 py-2">
                          {trip.place}, {trip.country}{" "}
                          {emoji[trip.country_code]}
                        </td>
                        <td className="border px-4 py-2">
                          {this.formatDate(trip.date_start)}
                        </td>
                        <td className="border px-4 py-2">
                          {this.formatDate(trip.date_end)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default NomadList;
